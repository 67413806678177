import styles from './EditProfile.module.css';
import React, {useContext, useEffect} from 'react';
import ContentCard from "../../components/contentCard/ContentCard";
import {useHistory} from "react-router-dom";
import saveIcon from '../../assets/save_task_icon.svg';
import backIcon from '../../assets/back_icon.svg';
import Icon from "../../components/icon/Icon";
import InnerOuterContainer from "../../components/innerOuterContainer/innerOuterContainer";
import SelectElement from "../../components/selectElement/SelectElement";
import {useForm} from "react-hook-form";
import {AuthContext} from "../../context/AuthContext";
import teamOptions from "../../helpers/teamOptions";
// Firebase imports
import {collection, doc, getDocs, updateDoc} from "firebase/firestore";
import {db} from "../../Firebase";
import {updateEmail, reauthenticateWithCredential, EmailAuthProvider} from "firebase/auth";
import {authFirebase} from "../../Firebase";

function EditProfile({setCurrentPage}) {

    //State management
    const [error, toggleError] = React.useState(false);
    const [errorReauth, toggleErrorReauth] = React.useState(false);
    const [clubs, setClubs] = React.useState([]);
    const [reAuth, toggleReAuth] = React.useState(false);
    const [authSucces, toggleAuthSucces] =React.useState(false);

    const history = useHistory();
    const {register, formState: {errors}, control, watch, handleSubmit, reset} = useForm();
    const {user, auth, toggleAuth} = useContext(AuthContext);

    useEffect(() => {
        // Change header currentPage state on page mounting and close drawer
        setCurrentPage("Profiel wijzigen");

        // Fetch all clubs for club select input
        async function fetchClubs() {
            toggleError(false);
            try {
                const clubsArray = [];
                const querySnapshot = await getDocs(collection(db, "club"));
                querySnapshot.forEach((doc) => {
                    clubsArray.push({
                        label: doc.data().name,
                        value: doc.data(),
                    });
                });
                setClubs(clubsArray);
            } catch (e) {
                console.error(e);
                toggleError(true);
            }
        }

        fetchClubs();
    }, [])

    async function handleSave(data) {
        toggleError(false);
        try {
            const taskRef = doc(db, "users", user.id);
            // If email is changed --> update user email in Firebase Authentication for login purposes
            if (user.email !== data.email) {
                await updateEmail(authFirebase.currentUser, data.email.toLowerCase());
                // Update Firestore user details document with new email
                await updateDoc(taskRef, {
                    firstName: data["first-name"],
                    lastName: data["last-name"],
                    email: data.email,
                    team: data.team.value,
                });
                // Update auth state
                toggleAuth({
                    ...auth,
                    user: {
                        ...user,
                        firstName: data["first-name"],
                        lastName: data["last-name"],
                        email: data.email,
                        team: data.team.value,
                    },
                });
                history.push("/profiel");
            } else {
                // Update Firestore user details document without email
                await updateDoc(taskRef, {
                    firstName: data["first-name"],
                    lastName: data["last-name"],
                    team: data.team.value,
                });
                // Update auth state
                toggleAuth({
                    ...auth,
                    user: {
                        ...user,
                        firstName: data["first-name"],
                        lastName: data["last-name"],
                        team: data.team.value,
                    },
                });
                history.push("/profiel");
            }

        } catch (e) {
            if (e.message.includes("Error (auth/requires-recent-login)")) {
                toggleReAuth(true);
            } else {
                console.error(e);
                toggleError(true);
            }
        }
    }

    async function reAuthenticate(data) {
        toggleErrorReauth(false);
        try {
            // Get current user
            const user = authFirebase.currentUser;
            // Construct credentials
            const credential = await EmailAuthProvider.credential(
                user.email,
                data.password
            );
            // Reauthenticate
            await reauthenticateWithCredential(user, credential).then(() => {
                reset();
                toggleAuthSucces(true);
            });
        } catch (e) {
            console.error(e);
            toggleErrorReauth(true);
            reset();

        }
    }

    return (
        <InnerOuterContainer>
            <h3 className={styles.h3}>Mijn gegevens</h3>
            <ContentCard stylingClass="standard">
                <form className={styles.form} onSubmit={handleSubmit(handleSave)}>
                    <input
                        type="text"
                        placeholder="Voornaam"
                        className={styles["edit-profile"]}
                        {...register("first-name", {
                            required: "Vul uw voornaam in",
                        })}
                        defaultValue={user.firstName}
                    />
                    <input
                        type="text"
                        placeholder="Achternaam"
                        className={styles["edit-profile"]}
                        {...register("last-name", {
                            required: "Vul uw achternaam in",
                        })}
                        defaultValue={user.lastName}
                    />
                    <input
                        type="email"
                        placeholder="Email"
                        className={styles["edit-profile"]}
                        {...register("email", {
                            required: "Vul uw email in",
                            pattern: {
                                value: /^\S+@\S+$/i,
                                message: "Ongeldige email",
                            }
                        })}
                        defaultValue={user.email}
                    />
                    {clubs.length > 0 && user.club &&
                        <SelectElement
                            name="team"
                            options={teamOptions(clubs, user.club)}
                            controller={control}
                            stylingClass="select"
                            isMulti={false}
                            placeholder="Selecteer uw team"
                            errorMessage="Selecteer een team"
                            defaultValues={{label: user.team, value: user.team}}
                        />
                    }
                    <div className={styles["icon-container"]}>
                        <Icon
                            text="Opslaan"
                            image={saveIcon}
                        />
                    </div>
                    {errors["first-name"] && <p className={styles.error}>{errors["first-name"].message}</p>}
                    {errors["last-name"] && <p className={styles.error}>{errors["last-name"].message}</p>}
                    {errors.email && <p className={styles.error}>{errors.email.message}</p>}
                    {errors["team"] && <p className={styles.error}>{errors["team"].message}</p>}
                    {error && <span className={styles.error}>Oeps, er ging iets mis. Probeer het opnieuw</span>}
                </form>
                {reAuth &&
                    <span className={styles["warning-popup"]}>
                        {authSucces ? <p>Authenticatie succesvol</p> : <p>Email wijziging</p>}
                        {authSucces ? <p>U kunt uw email aanpassen en opslaan.</p>: <p>U dient voor deze actie opnieuw te authenticeren.</p>}
                        {authSucces ? <button className={styles["auth-succes-button"]} type="button" onClick={() => {toggleAuthSucces(false); toggleReAuth(false)}}>Oke</button>
                            :
                            <form className={styles.form} onSubmit={handleSubmit(reAuthenticate)}>
                                <input
                                    type="password"
                                    placeholder="Wachtwoord"
                                    className={styles.reauth}
                                    {...register("password", {
                                        required: "Vul uw wachtwoord in",
                                    })}
                                />
                                <div className={styles["button-container"]}>
                                    <button className={styles["auth-button"]} type="submit">
                                        Authenticeren
                                    </button>
                                    <button
                                        className={styles["auth-button"]}
                                        type="button"
                                        onClick={() => {
                                            toggleReAuth(false)
                                        }}
                                    >
                                        Annuleren
                                    </button>
                                </div>
                                {errors.password && <p className={styles.error}>{errors.password.message}</p>}
                                {errorReauth &&
                                    <span className={styles.error}>Er ging iets verkeerd, controleer uw wachtwoord</span>}
                            </form>
                        }
                </span>
                }
            </ContentCard>
            <img
                onClick={() => {
                    history.goBack()
                }}
                className={styles["back-icon"]}
                src={backIcon}
                alt="back"
            />
        </InnerOuterContainer>
    );
}

export default EditProfile;
import styles from './Scoreboard.module.css';
import React, {useContext, useEffect} from 'react';
import ScoreboardTile from "../../components/scoreboardTile/ScoreboardTile";
import InnerOuterContainer from "../../components/innerOuterContainer/innerOuterContainer";
import {AuthContext} from "../../context/AuthContext";
import firstPlace from '../../assets/crown.svg'
import sortOnDrinkingTime from "../../helpers/sortOnDrinkingTime";
import calculateHours from "../../helpers/calculateHours";
import calculateMinutes from "../../helpers/calculateMinutes";
//Firebase imports
import {collection, getDocs, onSnapshot, query, where} from "firebase/firestore";
import {db, storage} from "../../Firebase";
import {getDownloadURL, ref} from "firebase/storage";

function ScoreboardPage({setCurrentPage}) {

    //State management
    const [drinkersTime, setDrinkersTime] = React.useState([]);
    const [profilePictureFirstPlace, setProfilePicturePictureFirstPlace] = React.useState();
    const [profilePictureSecondPlace, setProfilePicturePictureSecondPlace] = React.useState();
    const [profilePictureThirdPlace, setProfilePicturePictureThirdPlace] = React.useState();

    const {user} = useContext(AuthContext);

    useEffect(() => {
        // Change header currentPage state on page mounting and close drawer
        setCurrentPage("Scoreboard");
        // Set up Firestore listner for realtime scores updates
        // Query for user documents with same club and team as current user
        const q = query(collection(db, "users"),
            where("club", "==", user.club),
            where("team", "==", user.team));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const drinkersTimeArray = [];
            querySnapshot.forEach((doc) => {
                drinkersTimeArray.push(doc.data());
            });
            // Sort array on drinking time
            sortOnDrinkingTime(drinkersTimeArray);
            // Set state
            setDrinkersTime(drinkersTimeArray);
        });
        // Detach listner on unmount cycle
        return function cleanUp() {
            unsubscribe();
        }
    }, [])

    useEffect(() => {

        async function fetchProfilePictures() {
            try {
                drinkersTime.map(async (drinker, index) => {
                    if (index === 0) {
                        const pictureReference = await getDownloadURL(ref(storage, drinker.profilePicture));
                        setProfilePicturePictureFirstPlace(pictureReference);
                    }
                    else if (index === 1) {
                        const pictureReference = await getDownloadURL(ref(storage, drinker.profilePicture));
                        setProfilePicturePictureSecondPlace(pictureReference);
                    }
                    else if (index === 2) {
                        const pictureReference = await getDownloadURL(ref(storage, drinker.profilePicture));
                        setProfilePicturePictureThirdPlace(pictureReference);
                    }
                })
            } catch (e) {
                console.error(e);
            }
        }

        fetchProfilePictures();
    }, [drinkersTime])

    return (
        <InnerOuterContainer>
            <section className={styles["top-section"]}>
                {drinkersTime.length >= 2 &&
                    <div className={styles["podium-container"]}>
                        <figure className={styles["profile-picture-container"]}>
                            <img className={styles["profile-picture"]} id={styles["profile-picture-second-place"]}
                                 src={profilePictureSecondPlace} alt="profile-picture"/>
                        </figure>
                        <p className={styles.name}>{drinkersTime[1].firstName}</p>
                        <p className={styles.time}>{calculateHours(drinkersTime[1].time.season23_24)} uur {calculateMinutes(drinkersTime[1].time.season23_24)} min</p>
                    </div>
                }
                {drinkersTime.length > 0 &&
                    <div className={styles["podium-container"]} id={styles["first-place-container"]}>
                        <img className={styles.crown} src={firstPlace} alt="first-place"/>
                        <figure className={styles["profile-picture-container"]}
                                id={styles["profile-picture-container-first-place"]}>
                            <img className={styles["profile-picture"]} id={styles["profile-picture-first-place"]}
                                 src={profilePictureFirstPlace} alt="profile-picture"/>
                        </figure>
                        <p className={styles.name} id={styles["name-first-place"]}>{drinkersTime[0].firstName}</p>
                        <p className={styles.time}
                           id={styles["name-first-place"]}>{calculateHours(drinkersTime[0].time.season23_24)} uur {calculateMinutes(drinkersTime[0].time.season23_24)} min</p>
                    </div>
                }
                {drinkersTime.length >= 3 &&
                    <div className={styles["podium-container"]}>
                        <figure className={styles["profile-picture-container"]}>
                            <img className={styles["profile-picture"]} id={styles["profile-picture-third-place"]}
                                 src={profilePictureThirdPlace} alt="profile-picture"/>
                        </figure>
                        <p className={styles.name}>{drinkersTime[2].firstName}</p>
                        <p className={styles.time}>{calculateHours(drinkersTime[2].time.season23_24)} uur {calculateMinutes(drinkersTime[2].time.season23_24)} min</p>
                    </div>
                }
            </section>
            <section className={styles["bottom-section"]}>
                {drinkersTime.length === 0 && <span>Geen scores aanwezig</span>}
                {drinkersTime.length >= 4 && drinkersTime.map((drinker, index) => {
                    if (index > 2) {
                        return (
                            <ScoreboardTile
                                key={drinker.id}
                                title={drinker.firstName + " " + drinker.lastName}
                                place={(index + 1) + "e"}
                                time={`${calculateHours(drinker.time.season23_24)} u ${calculateMinutes(drinker.time.season23_24)} m`}
                            />
                        )
                    }
                })
                }
            </section>
        </InnerOuterContainer>
    );
}

export default ScoreboardPage;
import React, {useContext} from "react";
import './App.css';
import {Switch, Route, Redirect} from "react-router-dom";
import {AuthContext} from "./context/AuthContext";
import PrivateRoute from "./components/privateRoute/PrivateRoute";
// Import pages
import LoginPage from './pages/login/Login';
import RegisterPage from './pages/register/Register';
import Header from "./components/header/Header";
import Profile from "./pages/profile/Profile";
import StatisticsPage from "./pages/statistics/Statistics";
import Scoreboard from "./pages/scoreboard/Scoreboard";
import EditProfile from "./pages/editProfile/EditProfile";
import Management from "./pages/management/Management";
import NavigationDrawer from "./components/navigationDrawer/NavigationDrawer";
import CurrentSessionPage from "./pages/currentSession/CurrentSession";

function App() {
    //Context management
    const {isAuth} = useContext(AuthContext);

    // State management
    const [navDrawer, toggleNavDrawer] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState("Openstaande taken");
    return (
        <>
            {isAuth && <Header
                page={currentPage}
                navDrawer={navDrawer}
                toggleNavDrawer={toggleNavDrawer}
            />}
            {isAuth && <NavigationDrawer
                navDrawer={navDrawer}
                toggleNavDrawer={toggleNavDrawer}
            />}
            <Switch>
                <Route exact path="/">
                    {!isAuth ?
                        <LoginPage
                            toggleNavdrawer={toggleNavDrawer}
                        /> : <Redirect to="/huidige-sessie"/>
                    }
                </Route>
                <Route path="/registreren">
                    {!isAuth ?
                        <RegisterPage
                        /> : <Redirect to="/huidige-sessie"/>
                    }
                </Route>
                <PrivateRoute exact path="/huidige-sessie" isAuth={isAuth}>
                    <CurrentSessionPage
                        setCurrentPage={setCurrentPage}
                    />
                </PrivateRoute>
                <PrivateRoute path="/management" isAuth={isAuth}>
                    <Management
                        setCurrentPage={setCurrentPage}
                    />
                </PrivateRoute>
                <PrivateRoute path="/statistieken" isAuth={isAuth}>
                    <StatisticsPage
                        setCurrentPage={setCurrentPage}
                    />
                </PrivateRoute>
                <PrivateRoute path="/scoreboard" isAuth={isAuth}>
                    <Scoreboard
                        setCurrentPage={setCurrentPage}
                    />
                </PrivateRoute>
                <PrivateRoute exact path="/profiel" isAuth={isAuth}>
                    <Profile
                        setCurrentPage={setCurrentPage}
                    />
                </PrivateRoute>
                <PrivateRoute path="/profiel-wijzigen" isAuth={isAuth}>
                    <EditProfile
                        setCurrentPage={setCurrentPage}
                    />
                </PrivateRoute>
            </Switch>
        </>
    );
}

export default App;
import styles from './Management.module.css';
import React, {useEffect} from 'react';
import InnerOuterContainer from "../../components/innerOuterContainer/innerOuterContainer";
import {useForm} from "react-hook-form";

function Management({setCurrentPage}) {

    //State management

    // const [error, toggleError] = React.useState(false);
    // const [loading, toggleLoading] = React.useState(false);

    // const {formState: {errors}, control, handleSubmit} = useForm();

    // useEffect(() => {
    //     setCurrentPage("Management")
    // })

    return (
        <InnerOuterContainer>
            <h3 className={styles.h3}>Content</h3>
                Manager content
        </InnerOuterContainer>
    );
}

export default Management;
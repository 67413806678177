function convertSecondsToTimeString(timeInSeconds) {

// multiplied by 1000 so that the argument is in milliseconds, not seconds.
    const date = new Date(timeInSeconds * 1000);
// Hours part from the timestamp
    const hours = "0" + date.getHours();
// Minutes part from the timestamp
    const minutes = "0" + date.getMinutes();
// Seconds part from the timestamp
    const seconds = "0" + date.getSeconds();

// Will display time in 10:30:23 format
    return hours.substr(-2) + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
}

export default convertSecondsToTimeString;
import React from 'react';
import styles from './ScoreboardTile.module.css';

function ScoreboardTile({title, place, time}) {
    return (
        <div className={styles.tile} >
            <p className={styles.place}>{place}</p>
            <p className={styles.p}>{title}</p>
            <p className={styles.p}>{time}</p>
        </div>
    );
}

export default ScoreboardTile;
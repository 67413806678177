function convertSecondsToDateString(seconds) {
// Nederlandse versie numeriek
    const shortOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    };
    return new Date(seconds* 1000).toLocaleDateString('nl-NL', shortOptions);
}

export default convertSecondsToDateString;
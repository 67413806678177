function teamOptions(clubs, currentClub) {
    if(clubs.length > 0 && currentClub) {
        const {value: {teams}} = clubs.find((element) => element.label === currentClub)
        const teamsArray = [];
        teams.map((team) => {
            teamsArray.push({label: team, value: team});
        })
        return teamsArray;
    }
}

export default teamOptions;
import styles from './Profile.module.css';
import React, {useContext, useEffect} from 'react';
import ContentCard from "../../components/contentCard/ContentCard";
import editIcon from '../../assets/edit_task_icon.svg';
import deleteAccountIcon from '../../assets/delete_account.svg';
import changePasswordIcon from '../../assets/password_icon.svg'
import {useHistory} from "react-router-dom";
import Icon from "../../components/icon/Icon";
import InnerOuterContainer from "../../components/innerOuterContainer/innerOuterContainer";
import {AuthContext} from "../../context/AuthContext";
import {useForm} from "react-hook-form";
//Firebase imports
import {ref} from "firebase/storage";
import {authFirebase, db, storage} from "../../Firebase";
import {uploadBytes} from "firebase/storage";
import {deleteObject, getDownloadURL} from "firebase/storage";
import {collection, deleteDoc, doc, getDocs, updateDoc, query, where} from "firebase/firestore";
import {deleteUser, EmailAuthProvider, reauthenticateWithCredential, updatePassword} from "firebase/auth";
import Reauthentication from "../../components/reauthentication/Reauthentication";

function Profile({setCurrentPage}) {

    //Statemanagement
    const [uploadCard, toggleUploadCard] = React.useState(false);
    const [error, setError] = React.useState({error: false, message: ""});
    const [errorReauth, toggleErrorReauth] = React.useState(false);
    const [errorChangePassword, toggleErrorChangePassword] = React.useState(false);
    const [loading, toggleLoading] = React.useState(false);
    const [profilePictureUrl, setProfilePictureUrl] = React.useState("");
    const [deleteUserAction, toggleDeleteUserAction] = React.useState(false);
    const [changePasswordAction, toggleChangePasswordAction] = React.useState(false);
    const [changePasswordCard, toggleChangePasswordCard] = React.useState(false);

    const history = useHistory();
    const {register, formState: {errors}, handleSubmit, reset, watch} = useForm();
    const {user, auth, toggleAuth} = useContext(AuthContext);

    // React hook forms Watcher for password match check
    const checkPassword = watch('password');

    useEffect(() => {
        // Change header currentPage state on page mounting and close drawer
        setCurrentPage("Profiel");
        toggleLoading(true);

        async function fetchProfilePicture() {
            const pictureReference = await getDownloadURL(ref(storage, user.profilePicture));
            setProfilePictureUrl(pictureReference);
            toggleLoading(false);
        }

        fetchProfilePicture()
    }, [])

    // Fetch new profile picture on user profile picture change
    useEffect(() => {
        toggleLoading(true);

        async function fetchProfilePicture() {
            const pictureReference = await getDownloadURL(ref(storage, user.profilePicture));
            setProfilePictureUrl(pictureReference);
            toggleLoading(false);
        }

        fetchProfilePicture()
    }, [user.profilePicture])

    async function handleFileUpload(data) {
        setError({error: false, message: ""});
        toggleLoading(true);
        // Check if file is a image and not size is not bigger than 4MB
        if (!data.file[0].type.includes("image")) {
            setError({error: true, message: "Alleen afbeeldingen zijn toegestaan"});
        }
        if (data.file[0].size > 4000000) {
            setError({error: true, message: "De afbeelding mag niet groter zijn dan 4 MB"});
        }
        try {
            const imageRef = ref(storage, 'profilePictures/' + user.id + "_" + data.file[0].name);
            await uploadBytes(imageRef, data.file[0]);
            // Change profile picture url in Firebase user information
            // Create Firestore reference to scoreboardTile document
            const taskRef = doc(db, "users", user.id);
            // Update Firestore scoreboardTile document
            await updateDoc(taskRef, {
                // Make image name unique to trigger update useEffect
                profilePicture: 'profilePictures/' + user.id + "_" + data.file[0].name,
            });

            // Delete old profile picture
            const desertRef = ref(storage, user.profilePicture);
            // Don't delete the default profile picture
            if (desertRef.name !== "default_profile_picture.jpeg") {
                await deleteObject(desertRef);
            }
            toggleAuth({
                ...auth,
                user: {
                    ...user,
                    profilePicture: 'profilePictures/' + user.id + "_" + data.file[0].name,
                },
            });
        } catch (e) {
            console.error(e);
            setError({error: true, message: "Het uploaden is niet gelukt. Probeer opnieuw"});
        }
        toggleLoading(false);
        toggleUploadCard(false);
    }

    async function reAuthenticateAndToggleCard(data) {
        toggleErrorReauth(false);
        try {
            // Get current user
            const user = authFirebase.currentUser;
            // Construct credentials
            const credential = await EmailAuthProvider.credential(
                user.email,
                data.password
            );
            // Reauthenticate and toggle change password card
            await reauthenticateWithCredential(user, credential).then(async () => {
                toggleChangePasswordCard(true);
                toggleChangePasswordAction(false);
            });

        } catch (e) {
            console.error(e);
            toggleErrorReauth(true);
        }
    }

    async function changePassword(data) {
        toggleErrorChangePassword(false);
        reset();
        try {
            await updatePassword(authFirebase.currentUser, data.password);
            toggleChangePasswordCard(false);
        } catch (e) {
            console.error(e);
            toggleErrorChangePassword(true);
        }
    }

    async function reAuthenticateAndDeleteUser(data) {
        toggleErrorReauth(false);
        try {
            // Get current user
            const user = authFirebase.currentUser;
            // Construct credentials
            const credential = await EmailAuthProvider.credential(
                user.email,
                data.password
            );
            // Reauthenticate and delete user
            await reauthenticateWithCredential(user, credential).then(async () => {
                // Delete Firebase user sessions
                const q = query(collection(db, "sessions"), where("user.id", "==", auth.user.id));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    deleteDoc(doc.ref);
                });
                // Delete Firebase profile picture if picture is NOT default picture
                if (auth.user.profilePicture !== "profilePictures/default_profile_picture.jpeg") {
                    const profilePictureRef = ref(storage, auth.user.profilePicture);
                    await deleteObject(profilePictureRef);
                }
                // Delete Firebase user details documents
                await deleteDoc(doc(db, "users", auth.user.id));
                // Delete user from Firebase authentication
                // const userFirebase = authFirebase.currentUser;
                await deleteUser(user);
            });
            toggleDeleteUserAction(false);
            reset();
        } catch (e) {
            console.error(e);
            toggleErrorReauth(true);
        }
    }

    return (
        <InnerOuterContainer>
            <h3 className={styles.h3}>Mijn gegevens</h3>
            <ContentCard stylingClass="max-height">
                <section className={styles["content-wrapper"]}>
                    <div className={styles["top-section"]}>
                        {uploadCard &&
                            <span className={styles["file-upload"]}>
                                <p className={styles["upload-title"]}>Selecteer een bestand</p>
                                <form className={styles.form} onSubmit={handleSubmit(handleFileUpload)}>
                                    <input
                                        className={styles["input-file"]}
                                        type="file" {...register("file", {required: "Selecteer een bestand"})}
                                    />
                                    <div className={styles["buttons-container"]}>
                                        <button
                                            className={styles.button}
                                            type="submit">Uploaden
                                        </button>
                                        <button
                                            onClick={(e) => {
                                                toggleUploadCard(false);
                                                e.preventDefault();
                                            }}
                                            className={styles.button}>Annuleren
                                        </button>
                                    </div>
                                    {errors["file"] && <p className={styles.error}>{errors["file"].message}</p>}
                                    {error.error && <span className={styles.error}>{error.message}</span>}
                                    {loading && !error.error && <span>Even geduld...</span>}
                                </form>
                            </span>
                        }
                        {loading && !error ? <span>Profielfoto wordt opgehaald...</span>
                            :
                            <figure className={styles["profile-picture-container"]}>
                                <img
                                    className={styles.img}
                                    src={profilePictureUrl}
                                    alt="profiel"
                                />
                                <button
                                    type="button"
                                    onClick={() => {
                                        toggleUploadCard(true)
                                    }}
                                    disabled={uploadCard}
                                    className={styles["edit-profile-button"]}
                                >
                                    Wijzig
                                </button>
                            </figure>}
                        <div className={styles["name-container"]}>
                            <p className={styles.name}>{user.firstName}</p>
                            <p className={styles.name}>{user.lastName}</p>
                        </div>
                    </div>
                    <div className={styles["bottom-section"]}>
                        <p className={styles["profile-details"]}>Email: <span>{user.email}</span></p>
                        <p className={styles["profile-details"]}>Vereniging: <span>{user.club}</span></p>
                        <p className={styles["profile-details"]}>Team: <span>{user.team}</span></p>
                    </div>
                </section>
                <figure className={styles["icon-container"]}>
                    <Icon
                        text="Bewerken"
                        image={editIcon}
                        alt="edit"
                        onClick={() => {
                            history.push("/profiel-wijzigen")
                        }}
                    />
                    <Icon
                        text="Wijzig wachtwoord"
                        image={changePasswordIcon}
                        alt="change-password"
                        onClick={() => {
                            toggleChangePasswordAction(true);
                        }}
                    />
                    <Icon
                        text="Verwijderen"
                        image={deleteAccountIcon}
                        alt="delete-account"
                        onClick={() => {
                            toggleDeleteUserAction(true);
                        }}
                    />
                </figure>
                {error.error && <span className={styles.error}>{error.message}</span>}
                {changePasswordAction &&
                    <Reauthentication
                        title="Authenticeren"
                        text="Voer vanwege veiligheidsredenen uw wachtwoord opnieuw in."
                        buttonText="Authenticeren"
                        submitFunction={reAuthenticateAndToggleCard}
                        toggleCard={toggleChangePasswordAction}
                        error={errorReauth}
                        toggleError={toggleErrorReauth}
                    />
                }
                {deleteUserAction &&
                    <Reauthentication
                        title="Profiel verwijderen"
                        text="Voer vanwege veiligheidsredenen uw wachtwoord opnieuw in. Let op: hierna word uw account en bijbehorende data definitief verwijdert!"
                        buttonText="Verwijderen"
                        submitFunction={reAuthenticateAndDeleteUser}
                        toggleCard={toggleDeleteUserAction}
                        error={errorReauth}
                        toggleError={toggleErrorReauth}
                    />
                }
                {changePasswordCard &&
                    <span className={styles["warning-popup"]}>
                        <p>Wachtwoord wijzigen</p>
                        <p>Vul hieronder uw nieuwe wachtwoord in.</p>
                    <form onSubmit={handleSubmit(changePassword)}>
                        <input
                            type="password"
                            placeholder="Wachtwoord"
                            {...register("password", {
                                minLength: {
                                    value: 6,
                                    message: "Het wachtwoord moet uit minimaal 6 karakters bestaan",
                                },
                                required: "Vul een wachtwoord in",
                            })}
                            className={styles.reauth}
                        />
                    <input
                        type="password"
                        placeholder="Herhaal wachtwoord"
                        {...register("password-check", {
                            required: "Vul opnieuw uw wachtwoord in",
                            validate: value =>
                                value === checkPassword || "Wachtwoorden komen niet overeen"
                        })}
                        className={styles.reauth}
                    />
                        <div className={styles["button-container"]}>
                        <button className={styles["auth-button"]} type="submit">
                            Wijzig wachtwoord
                        </button>
                        <button
                            className={styles["auth-button"]}
                            type="button"
                            onClick={() => {
                                reset();
                                toggleChangePasswordCard(false);
                                toggleErrorChangePassword(false);
                            }}
                        >
                            Annuleren
                        </button>
                        </div>
                        {errors.password && <p className={styles.error}>{errors.password.message}</p>}
                        {errors["password-check"] && <p className={styles.error}>{errors["password-check"].message}</p>}
                        {errorChangePassword && <span className={styles.error}>Er ging iets verkeerd bij het veranderen van uw wachtwoord. Probeer het opnieuw</span>}
                    </form>
                </span>
                }
            </ContentCard>
        </InnerOuterContainer>
    );
}

export default Profile;
import React from 'react';
import styles from './Reauthentication.module.css';
import {useForm} from "react-hook-form";

function Reauthentication({title, text, buttonText, submitFunction, toggleCard, error, toggleError}) {

    const {register, formState: {errors}, handleSubmit} = useForm();

    return (
        <span className={styles["warning-popup"]}>
                        <p>{title}</p>
                        <p>{text}</p>
                    <form onSubmit={handleSubmit(submitFunction)}>
                        <input
                            type="password"
                            placeholder="Wachtwoord"
                            className={styles.reauth}
                            {...register("password", {
                                required: "Vul uw wachtwoord in",
                            })}
                        />
                        <div className={styles["button-container"]}>
                        <button className={styles["auth-button"]} type="submit">
                            {buttonText}
                        </button>
                        <button
                            className={styles["auth-button"]}
                            type="button"
                            onClick={() => {
                                toggleCard(false);
                                toggleError(false);
                            }}
                        >
                            Annuleren
                        </button>
                        </div>
                        {errors.password && <p className={styles.error}>{errors.password.message}</p>}
                        {error &&
                            <span className={styles.error}>Er ging iets verkeerd, controleer uw wachtwoord</span>}
                    </form>
                </span>
    );
}

export default Reauthentication;
import React, {useContext, useEffect} from 'react';
import styles from './Header.module.css'
import navigationHamburger from '../../assets/navigation_hamburger.svg'
import {AuthContext} from "../../context/AuthContext";
import createCurrentDate from "../../helpers/createCurrentDate";
//Firebase imports
import {db} from "../../Firebase";
import {collection, query, where, onSnapshot} from "firebase/firestore";

function Header({page, navDrawer, toggleNavDrawer}) {

    //State management
    const [activeSession, setActiveSession] = React.useState([]);

    const {user} = useContext(AuthContext);

    useEffect(() => {
        // Create query for active user session
        const q = query(collection(db, "sessions"),
            where("active", "==", true),
            where("user.id", "==", user.id));
        // Setup Firebase listner for active user session
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const activeUserSessionArray = [];
            querySnapshot.forEach((doc) => {
                activeUserSessionArray.push(doc.data());
            });
            setActiveSession(activeUserSessionArray);
        });

        return function cleanUp() {
            unsubscribe();
        }
    }, [])

    function handleOnClick() {
        toggleNavDrawer(!navDrawer);
    }

    return (
        <header className={styles.header}>
            <div className={styles["content-container"]}>
                <section className={styles["left-side"]}>
                    <img
                        className={styles.img}
                        src={navigationHamburger}
                        onClick={handleOnClick}
                        alt="navigation-menu"
                        width="30px"
                        height="30px"
                    />
                    <h1 className={styles.h1}>{page}</h1>
                    <p className={styles.date}>{createCurrentDate()}</p>
                </section>
                <section className={styles["right-side"]}>
                    <div className={styles["session-container"]}>
                        {activeSession.length > 0 ? <p className={styles["session-active"]}>Sessie actief</p>
                            :
                            <p className={styles["session-inactive"]}>Sessie inactief</p>
                        }
                    </div>
                </section>
                <div className={styles.line}/>
            </div>
        </header>
    );
}

export default Header;
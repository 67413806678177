import React, {createContext, useEffect, useState} from 'react';
import styles from './AuthContext.module.css';
// Firebase imports
import {doc, setDoc, getDoc, query, collection, where, getDocs, updateDoc} from "firebase/firestore";
import {authFirebase, db} from "../Firebase";
import {deleteUser, onAuthStateChanged, signOut} from "firebase/auth";
import {useHistory} from "react-router-dom";

// Create context
export const AuthContext = createContext({});

function AuthContextProvider({children}) {

    //State management
    const [auth, toggleAuth] = useState({
        isAuth: false,
        user: null,
        status: 'pending',
    });

    const history = useHistory();

    // Check for logged in user on mount cycle, synchronize session time and get user information
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(authFirebase, (user) => {
            if (user) {
                async function getUserInformation() {
                    try {
                        const docRef = doc(db, "users", user.uid);
                        const docSnap = await getDoc(docRef);

                        if (docSnap.exists()) {
                            const userInformation = docSnap.data();

                            // SYNCHRONIZE "EMAIL" in user document (in case the user resets it with email link)
                            if (userInformation.email !== user.email) {
                                const emailRef = doc(db, "users", user.uid);
                                // Update Firestore user document
                                await updateDoc(emailRef, {
                                    email: user.email
                                });
                            }


                            // SYNCHRONIZE "TIME" IN "USER" COLLECTION WITH REAL SAVED SESSIONS
                            // Query for all inactive sessions of current user
                            const q = query(collection(db, "sessions"),
                                where("user.id", "==", userInformation.id),
                                where("active", "==", false),
                                where("season", "==", "season23_24")
                            );
                            // Fetch all inactive sessions of current user and add them together
                            const querySnapshot = await getDocs(q);
                            let totalTime = 0;
                            querySnapshot.forEach((doc) => {
                                totalTime += doc.data().totalSessionTime;
                            });
                            // If total time from real saved sessions not match "time" in "user" collection --> update "user" collection
                            if(totalTime !== userInformation.time.season23_24) {
                                const userRef = doc(db, "users", userInformation.id);

                                // ***CAUTION*** when upgrading to season 23/24 make sure you include the 22/23 time in the "time" object! Like season23_24 below..
                                // time: {
                                // season22_23: userInformation.time.season22_23,
                                // season23_24: totalTime,
                                // }

                                // Update Firestore user document
                                await updateDoc(userRef, {
                                    time: {
                                        season22_23: userInformation.time.season22_23,
                                        season23_24: totalTime
                                    }
                                });
                            }
                            // Update auth state
                            toggleAuth({
                                isAuth: true,
                                user: {
                                    id: userInformation.id,
                                    firstName: userInformation.firstName,
                                    lastName: userInformation.lastName,
                                    email: user.email,
                                    club: userInformation.club,
                                    team: userInformation.team,
                                    seasonWins : userInformation.seasonWins,
                                    time: {
                                        season22_23: userInformation.time.season22_23,
                                        season23_24: totalTime,
                                    },
                                    profilePicture: userInformation.profilePicture,
                                },
                                status: 'done',
                            });
                        } else {
                            toggleAuth({
                                ...auth,
                                status: "error",
                            });
                        }
                    } catch (e) {
                        console.error(e);
                        toggleAuth({
                            ...auth,
                            status: "error",
                        });
                    }

                }

                getUserInformation();
            } else {
                // No user was logged in or user just logged out
                toggleAuth({
                    isAuth: false,
                    user: null,
                    status: 'done',
                });
            }

        });
        // Unsubscribe on unmount cycle
        return function cleanUp() {
            unsubscribe();
        }
    }, [])

    // Create firebase document with user information after registration and set auth state
    async function createUserInformation(userCredential, data) {
        try {
            // Create new firebase document with user info and uid as document id
            await setDoc(doc(db, "users", userCredential.user.uid), {
                id: userCredential.user.uid,
                firstName: data["first-name"],
                lastName: data["last-name"],
                email: data.email.toLowerCase(),
                club: data.club.name,
                team: data.team,
                seasonWins: [],
                time: {
                    season22_23: 0,
                    season23_24: 0,
                },
                profilePicture: "profilePictures/default_profile_picture.jpeg",
            });
            toggleAuth({
                isAuth: true,
                user: {
                    id: userCredential.user.uid,
                    firstName: data["first-name"],
                    lastName: data["last-name"],
                    email: data.email.toLowerCase(),
                    club: data.club.name,
                    team: data.team,
                    seasonWins: [],
                    time: {
                        season22_23: 0,
                        season23_24: 0,
                    },
                    profilePicture: "profilePictures/default_profile_picture.jpeg",
                },
                status: 'done',
            });
            history.push("/huidige-sessie");
        } catch (e) {
            console.error(e);
            // Delete firebase user so user can try again with same email
            await deleteUser(userCredential.user);
            toggleAuth({
                ...auth,
                status: "error",
            });
        }
    }

    // Log user out and let onAuthStateChange() handle the auth state
    async function logout() {
        try {
            await signOut(authFirebase);
        } catch (e) {
            console.error(e);
            toggleAuth({
                ...auth,
                status: "error",
            });
        }
    }

    const contextData = {
        isAuth: auth.isAuth,
        user: auth.user,
        auth: auth,
        toggleAuth: toggleAuth,
        logout: logout,
        createUserInformation: createUserInformation,
    };
    return (
        <AuthContext.Provider value={contextData}>
            {auth.status === 'done' && children}
            {auth.status === 'pending' && <p className={styles.error}>Even geduld...</p>}
            {auth.status === 'error' && <p className={styles.error}>Oeps, er ging iets mis! Ververs de pagina.</p>}
        </AuthContext.Provider>
    );
}

export default AuthContextProvider;
import axios from "axios";

async function getCurrentTime() {
    try {
        const currentTime = await axios.get("https://worldtimeapi.org/api/timezone/Europe/Amsterdam");
        return new Date(currentTime.data.datetime);
    } catch (e) {
        console.error(e)
        //Failsave when worldtimeapi does not respond -> get datetime locally
        return new Date();
    }
}

export default getCurrentTime;
import getCurrentTime from "./getCurrentTime";

async function getLocation(setError, toggleLocationCheck, session, registrationFunction, toggleWrongLocationCard) {
    //Object for storing coordinates
    let coordinates = {};

    const sessionInformation = session;
    toggleLocationCheck(true);
    toggleWrongLocationCard(false);
    setError({error: false, message: ""});

    //Get current time from worldtime API
    const time = await getCurrentTime();

    if (time.getDay() === 0 && time.getHours() >= 12) {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition, showError);
        } else {
            setError({error: true, message: "Locatiebepaling wordt niet ondersteund"})
            toggleLocationCheck(false);
        }
    }

    else {
        if(time.getDay() !== 0) {
            setError({error: true, message: `Het is nog geen zondag. Nog ${7 - time.getDay()} nachtjes slapen.`});
            toggleLocationCheck(false);
        }

        else if (time.getDay() === 0 && time.getHours() <= 12) {
            setError({error: true, message: `Het is nog geen 12 uur...`});
            toggleLocationCheck(false);
        }
    }

    function showPosition(position) {
        coordinates = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
        };
        if (coordinates.latitude > 52.518649 && coordinates.latitude < 52.519175 && coordinates.longitude > 6.267233 && coordinates.longitude < 6.268110) {
            registrationFunction(false);
        } else {
            if (sessionInformation.active) {
                toggleWrongLocationCard(true);
                toggleLocationCheck(false);
            } else {
                setError({error: true, message: "Je locatie is niet juist, registratie kan niet gestart worden"});
                toggleLocationCheck(false);
            }
        }
        coordinates = {};
    }

    function showError(error) {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                setError({error: true, message: "Locatiebepaling is geweigerd. Wijzig uw locatierechten"})
                toggleLocationCheck(false);
                break;
            case error.POSITION_UNAVAILABLE:
                setError({error: true, message: "Locatiebepaling is niet beschikbaar"})
                toggleLocationCheck(false);
                break;
            case error.TIMEOUT:
                setError({error: true, message: "Request time out"})
                toggleLocationCheck(false);
                break;
            case error.UNKNOWN_ERROR:
                toggleLocationCheck(false);
                break;
        }
    }
}

export default getLocation;
import styles from './Statistics.module.css';
import React, {useContext, useEffect} from 'react';
import InnerOuterContainer from "../../components/innerOuterContainer/innerOuterContainer";
import ContentCard from "../../components/contentCard/ContentCard";
import {AuthContext} from "../../context/AuthContext";
import calculateHours from "../../helpers/calculateHours";
import calculateMinutes from "../../helpers/calculateMinutes";
import convertSecondsToDateString from "../../helpers/convertSecondsToDateString";
//Firebase imports
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../../Firebase";

function StatisticsPage({setCurrentPage}) {

    //State management
    const [error, toggleError] = React.useState(false);
    const [loading, toggleLoading] = React.useState(false);
    const [sessionHistory, setSessionHistory] = React.useState([]);

    const {user} = useContext(AuthContext);

    useEffect(() => {
        // Change header currentPage state on page mounting and close drawer
        setCurrentPage("Statistieken");
        toggleLoading(true);
        toggleError(false);

        async function fetchSessionHistory() {
            try {
                const q = query(collection(db, "sessions"),
                    where("user", "==", {
                        firstName: user.firstName,
                        lastName: user.lastName,
                        id: user.id,
                    }),
                    where("active", "==", false),
                    where("season", "==", "season23_24")
                );
                // Fetch all inactive sessions of current user and add them together
                const querySnapshot = await getDocs(q);
                let sessionHistoryArray = [];
                querySnapshot.forEach((doc) => {
                    sessionHistoryArray.push(doc.data());
                });
                setSessionHistory(sessionHistoryArray);
            } catch (e) {
                console.error(e);
                toggleError(true);
            }
            toggleLoading(false);
        }

        fetchSessionHistory();
    }, [])

    return (
        <InnerOuterContainer>
            <h3 className={styles.h3}>Mijn drinktijd</h3>
            <ContentCard stylingClass="standard">
                {loading && !error ? <span>Gegevens worden opgehaald...</span>
                    :
                    <p className={styles.p}>Je totale drinktijd is <span
                        className={styles.time}>{calculateHours(user.time.season23_24)} uur</span> en <span
                        className={styles.time}>{calculateMinutes(user.time.season23_24)} minuten</span></p>
                }
            </ContentCard>
            <h3 className={styles.h3}>Sessie historie</h3>
            <ContentCard stylingClass="table">
                {loading && !error ? <span>Gegevens worden opgehaald...</span>
                    :
                    <table className={styles.table}>
                        <thead className={styles.thead}>
                        <tr>
                            <th className={styles.th}>Datum</th>
                            <th className={styles.th}>Duur</th>
                        </tr>
                        </thead>
                        <tbody>
                        {sessionHistory.length > 0 &&
                            sessionHistory.map((session) => {
                                return (
                                    <tr key={session.docId}>
                                        <td className={styles.td}>{convertSecondsToDateString(session.loginTime.seconds)}</td>
                                        <td className={styles.td}>{calculateHours(session.totalSessionTime)} uur
                                            en {calculateMinutes(session.totalSessionTime)} minuten
                                        </td>
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </table>
                }
                {sessionHistory.length === 0 && !error && !loading &&
                    <span className={styles["no-data-message"]}>Geen sessie historie aanwezig</span>
                }
                {error && <span className={styles.error}>Er ging iets mis bij het ophalen van de sessie historie. Probeer het opnieuw...</span>}
            </ContentCard>
        </InnerOuterContainer>
    );
}

export default StatisticsPage;
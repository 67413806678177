import styles from './CurrentSession.module.css';
import React, {useContext, useEffect} from 'react';
import ContentCard from "../../components/contentCard/ContentCard";
import InnerOuterContainer from "../../components/innerOuterContainer/innerOuterContainer";
import getLocation from "../../helpers/getLocation";
import {AuthContext} from "../../context/AuthContext";
import convertSecondsToTimeString from "../../helpers/convertSecondsToTimeString";

//Firebase imports
import {collection, doc, getDocs, updateDoc, where, query, addDoc, deleteDoc, onSnapshot} from "firebase/firestore";
import {db} from "../../Firebase";
import getCurrentTime from "../../helpers/getCurrentTime";

function CurrentSessionPage({setCurrentPage}) {

    //State management
    const [error, setError] = React.useState({error: false, message: ""});
    const [errorActiveDrinkers, toggleErrorActiveDrinkers] = React.useState(false);
    const [loading, toggleLoading] = React.useState(false);
    const [session, setSession] = React.useState(false);
    const [wrongLocationCard, toggleWrongLocationCard] = React.useState(false);
    const [timer, setTimer] = React.useState("00:00:00");
    const [activeDrinkers, setActiveDrinkers] = React.useState([]);
    const [locationCheck, toggleLocationCheck] = React.useState(false);

    const {user, auth, toggleAuth} = useContext(AuthContext);

    // Look for active session in Firestore
    useEffect(() => {
        // Change header currentPage state on page mounting and close drawer
        setCurrentPage("Huidige sessie");
        setError({error: false, message: ""});
        toggleLoading(true);

        async function fetchActiveSession() {
            try {
                const q = query(collection(db, "sessions"),
                    where("active", "==", true),
                    where("user", "==", {
                        id: user.id,
                        firstName: user.firstName,
                        lastName: user.lastName,
                    }));
                // Update Firestore scoreboardTile document
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    const sessionArray = [];
                    sessionArray.push(doc.data());
                    if (sessionArray.length === 1) {
                        setSession({
                            docId: sessionArray[0].docId,
                            loginTime: new Date(sessionArray[0].loginTime.seconds * 1000),
                            season: sessionArray[0].season,
                            active: sessionArray[0].active,
                            user: {
                                userId: sessionArray[0].user.userId,
                                firstName: sessionArray[0].user.firstName,
                                lastName: sessionArray[0].user.lastName,
                            },
                        });
                    }
                    else {}
                });
            } catch (e) {
                // Filter harmless Firebase security rule error
                // Caused by the fact that user is logged out and OnauthStateChanged() steps in and redirects the user
                // The logout causes a short window in which the user is not authenticated to see data
                if (!e.message.includes("Missing or insufficient permissions")) {
                    console.error(e);
                    toggleLoading(false);
                    setError({
                        error: true,
                        message: "Er is iets misgegaan bij het ophalen van de sessie. Probeer het opnieuw"
                    });
                }
            }
            toggleLoading(false);
        }

        fetchActiveSession();
    }, []);

    // Fetch active drinkers and save tot state
    useEffect(() => {
        toggleLoading(true);
        toggleErrorActiveDrinkers(false);

        const q = query(collection(db, "sessions"),
            where("active", "==", true),
            where("club", "==", user.club),
            where("team", "==", user.team),
        );

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const activeDrinkersArray = [];
            querySnapshot.forEach((doc) => {
                if (doc.data().user.id !== user.id) {
                    activeDrinkersArray.push(doc.data());
                }
            });
            setActiveDrinkers(activeDrinkersArray);
        });
        toggleLoading(false);
        return function cleanUp() {
            unsubscribe();
        }
    }, [])

    //Start timer interval on session.loginTime state change
    useEffect(() => {
        const interval = setInterval(() => {
            if (!session.loginTime) {
                clearInterval(interval);
            } else {
                const format = {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                }
                // Minus 6 seconds to eliminate the small difference in from the worldtime API and local time from "new Date()"
                const calculatedTimeInSeconds = (Math.floor(new Date().getTime() / 1000) - (Math.floor(new Date(session.loginTime).getTime() / 1000)));
                const hours = Math.floor(calculatedTimeInSeconds / 3600);
                const minutes = Math.floor(calculatedTimeInSeconds / 60) % 60;
                const seconds = Math.floor(calculatedTimeInSeconds % 60);

                setTimer(`${hours.toLocaleString("nl-NL", format)}:${minutes.toLocaleString("nl-NL", format)}:${seconds.toLocaleString("nl-NL", format)}`);
            }
        }, 1000);
        // Clear interval on unmount
        return function cleanUp() {
            clearInterval(interval);
        }
    }, [session.loginTime]);

    async function startRegistration() {
        setError({error: false, message: ""})
        try {
            //Create timestamp
            const time = await getCurrentTime();
            // Create new firebase document with user info and uid as document id
            const docRef = await addDoc(collection(db, "sessions"), {
                loginTime: time,
                season: "season23_24",
                active: true,
                club: user.club,
                team: user.team,
                user: {
                    id: user.id,
                    firstName: user.firstName,
                    lastName: user.lastName,

                }
            });
            // Update Firebase session document with docId for easy future lookups
            const taskRef = doc(db, "sessions", docRef._key.path.segments[1]);
            await updateDoc(taskRef, {
                docId: docRef._key.path.segments[1],
            });
            setSession({
                docId: docRef._key.path.segments[1],
                loginTime: time,
                season: "season23_24",
                active: true,
                club: user.club,
                team: user.team,
                user: {
                    id: user.id,
                    firstName: user.firstName,
                    lastName: user.lastName,

                }
            });
        } catch (e) {
            console.error(e);
            toggleLocationCheck(false);
            setError({
                error: true,
                message: "Er ging iets mis bij het starten van de registratie. Probeer het opnieuw"
            });
        }
        toggleLocationCheck(false);
    }

    async function stopRegistration(wrongLocation) {
        try {
            // Create timestamp for logout
            const logoutTime = await getCurrentTime();
            if (wrongLocation) {
                // Delete current session from Firebase due to wrong location
                await deleteDoc(doc(db, "sessions", session.docId));
                setSession(false);
                setTimer("00:00:00");
            } else {
                // Delete session if it is shorter than 60 seconds (counter pollution of Firestore with short sessions)
                if (((logoutTime.getTime() - session.loginTime.getTime()) / 1000) < 60) {
                    // Delete current session from Firebase due to to short session
                    await deleteDoc(doc(db, "sessions", session.docId));
                    setSession(false);
                    setTimer("00:00:00");
                } else {
                    // Update Firebase session document
                    // Create Firestore reference to session document
                    const sessionRef = doc(db, "sessions", session.docId);
                    // Update Firestore session document
                    await updateDoc(sessionRef, {
                        logoutTime: logoutTime,
                        totalSessionTime: Math.floor((logoutTime.getTime() - session.loginTime.getTime()) / 1000),
                        active: false,
                    });
                    // Update totalTime in Firebase user information
                    // Create Firestore reference to scoreboardTile document
                    const userRef = doc(db, "users", user.id);
                    // Update Firestore scoreboardTile document
                    await updateDoc(userRef, {
                        time: {
                            season22_23: user.time.season22_23,
                            season23_24: user.time.season23_24 + Math.floor((logoutTime.getTime() - session.loginTime.getTime()) / 1000),
                        }
                    });
                    setSession(false);
                    toggleAuth({
                        ...auth,
                        user: {
                            ...user,
                            time: {
                                season22_23: user.time.season22_23,
                                season23_24: user.time.season23_24 + Math.floor((logoutTime.getTime() - session.loginTime.getTime()) / 1000),
                            },
                        },
                    });
                    setTimer("00:00:00");
                }
            }
            toggleWrongLocationCard(false);
        } catch (e) {
            console.error(e);
            toggleLocationCheck(false);
            setError({
                error: true,
                message: "Er ging iets mis bij het afsluiten van de registratie. Probeer het opnieuw"
            });
        }
        toggleLocationCheck(false);
    }

    return (
        <InnerOuterContainer>
            <h3 className={styles.h3}>Sessie timer</h3>
            <ContentCard stylingClass="standard">
                {loading && <span className={styles.p}>Gegevens worden opgehaald...</span>}
                {locationCheck && <span className={styles.p}>Locatie wordt gecontroleerd...</span>}
                {session.loginTime && !loading && !error.error && <span className={styles.counter}>{timer}</span>}
                {!session.loginTime && !locationCheck && !error.error &&
                    <p className={styles.p}>Je bent momenteel niet ingeklokt</p>}
                {error.error && <span className={styles.error}>{error.message}</span>}
                {session.active ?
                    <button
                        disabled={locationCheck}
                        onClick={() => {
                            getLocation(setError, toggleLocationCheck, session, stopRegistration, toggleWrongLocationCard);
                        }}
                        className={styles.button} type="button">Uitklokken
                    </button>
                    :
                    <button
                        disabled={locationCheck}
                        onClick={() => {
                            getLocation(setError, toggleLocationCheck, session, startRegistration, toggleWrongLocationCard);
                        }}
                        className={styles.button} type="button">Inklokken
                    </button>
                }
                {wrongLocationCard &&
                    <span className={styles["wrong-location"]}>
                        <p>Uw locatie is niet juist, de sessie kan niet afgesloten worden.</p>
                        <p className={styles.p}>Toch op de juiste locatie? Klik op 'Opnieuw proberen.' Vergeten uit te klokken? Uw kunt op "Verwijderen" klikken, uw sessie telt niet mee (openstaande sessies worden automatisch verwijderd).</p>
                        <div className={styles["button-container"]}>
                            <button
                                onClick={() => {
                                    getLocation(setError, toggleLocationCheck, session, stopRegistration, toggleWrongLocationCard);
                                }}
                                className={styles["button-wrong-location"]}>Opnieuw proberen</button>
                            <button
                                onClick={() => {
                                    stopRegistration(true)
                                }}
                                className={styles["button-wrong-location"]}>Verwijderen</button>
                        </div>
                    </span>
                }
            </ContentCard>
            <h3 className={styles.h3}>Actieve drinkers</h3>
            <ContentCard stylingClass="table">
                {loading ? <span className={styles.p}>Gegevens worden opgehaald...</span>
                    :
                    <table className={styles.table}>
                        <thead className={styles.thead}>
                        <tr>
                            <th className={styles.th}>Voornaam</th>
                            <th className={styles.th}>Achternaam</th>
                            <th className={styles.th}>Ingeklokt vanaf</th>
                        </tr>
                        </thead>
                        <tbody>
                        {activeDrinkers.length > 0 &&
                            activeDrinkers.map((drinker) => {
                                return (
                                    <tr key={drinker.docId}>
                                        <td className={styles.td}>{drinker.user.firstName}</td>
                                        <td className={styles.td}>{drinker.user.lastName}</td>
                                        <td className={styles.td}>{convertSecondsToTimeString(drinker.loginTime.seconds).substring(0, 5)}</td>
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </table>}
                {activeDrinkers.length === 0 && !errorActiveDrinkers &&
                    <span className={styles["no-data-message"]}>Er zijn geen andere actieve drinkers</span>
                }
                {errorActiveDrinkers &&
                    <span className={styles.error}>Er is iets misgegaan bij het ophalen van de actieve drinkers. Probeer het opnieuw...</span>}
            </ContentCard>
        </InnerOuterContainer>
    );
}

export default CurrentSessionPage;
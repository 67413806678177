import React, {useEffect} from 'react';
import styles from './Login.module.css';
import SubmitButton from '../../components/submitButton/SubmitButton';
import {Link} from 'react-router-dom';
import {useForm} from "react-hook-form";
// Firebase imports
import {authFirebase} from "../../Firebase";
import {signInWithEmailAndPassword, sendPasswordResetEmail} from "firebase/auth";

function LoginPage({toggleNavdrawer}) {

    // Context management
    const [error, toggleError] = React.useState(false);
    const [errorResetPassword, toggleErrorResetPassword] = React.useState(false);
    const [submitButtonDisable, toggleSubmitButtonDisable] = React.useState(false);
    const [resetPasswordCard, toggleResetPasswordCard] = React.useState(false);

    const {register, handleSubmit, formState: {errors}} = useForm();
    const {
        register: register1,
        formState: {errors: errors1},
        handleSubmit: handleSubmit1,
        reset: reset1
    } = useForm();

    // Close navbar after user logout
    useEffect(() => {
        toggleNavdrawer(false);
    }, [])

    async function loginUser(data) {
        toggleError(false);
        toggleSubmitButtonDisable(true);
        try {
            //Log user in and let onAuthStateChange handle the auth state(in Authcontext)
            await signInWithEmailAndPassword(authFirebase, data.email, data.password);
        } catch (e) {
            toggleError(true);
            console.error(e);
        }
        toggleSubmitButtonDisable(true);
    }

    async function resetPassword(data) {
        toggleErrorResetPassword(false);
        try {
            await sendPasswordResetEmail(authFirebase, data["email-reset"]);
            reset1();
            toggleResetPasswordCard(false);
        } catch (e) {
            console.error(e);
            toggleErrorResetPassword(true);
        }
    }

    return (
        <main className={styles.main}>
            <h1 className={styles.h1}>KantineApp</h1>
            <div className={styles["form-card"]}>
                <form className={styles.form} onSubmit={handleSubmit(loginUser)}>
                    <input
                        type="text"
                        placeholder="Email"
                        className={styles["login-registration"]}
                        {...register("email", {
                            required: "Vul uw email in",
                        })}
                    />
                    <input
                        type="password"
                        placeholder="Wachtwoord"
                        className={styles["login-registration"]}
                        {...register("password", {
                            required: "Vul uw wachtwoord in",
                        })}
                    />
                    <SubmitButton
                        text="Login"
                        disabled={submitButtonDisable}
                    />
                    {errors.email && <p className={styles.error}>{errors.email.message}</p>}
                    {errors.password && <p className={styles.error}>{errors.password.message}</p>}
                    {error && <span className={styles.error}>Er ging iets verkeerd, controleer uw gegevens</span>}
                </form>
                <p className={styles.p}>Wachtwoord vergeten door al dat gezoep? Vraag <span onClick={() => {
                    toggleResetPasswordCard(true)
                }} id={styles["password-reset"]}>hier</span> een reset mail aan</p>
                <br/>
                <p className={styles.p}>Geen account maar wel machtige dorst? Registreer <Link
                    to="/registreren">hier</Link></p>
            </div>

            {resetPasswordCard &&
                <span className={styles["reset-password-popup"]}>
                        <p>Wachtwoord reset</p>
                        <p>Vul het email adres in waarmee uw account geregisteerd is</p>
                    <form onSubmit={handleSubmit1(resetPassword)}>
                        <input
                            type="text"
                            placeholder="Email"
                            className={styles.reauth}
                            {...register1("email-reset", {
                                required: "Vul uw email in",
                                pattern: {
                                    value: /^\S+@\S+$/i,
                                    message: "Ongeldige email",
                                }
                            })}
                        />
                        <div className={styles["button-container"]}>
                        <button className={styles["auth-button"]}>
                            Stuur link
                        </button>
                        <button
                            className={styles["auth-button"]}
                            type="button"
                            onClick={() => {
                                toggleResetPasswordCard(false);
                                toggleErrorResetPassword(false);
                                reset1();
                            }}
                        >
                            Annuleren
                        </button>
                            </div>
                        {errors1["email-reset"] && <p className={styles.error}>{errors1["email-reset"].message}</p>}
                        {errorResetPassword &&
                            <span className={styles.error}>Er ging iets verkeerd met het versturen van de reset mail. Probeer het opnieuw</span>}
                    </form>
                </span>
            }

        </main>
    );
}

export default LoginPage;